import React, { Component } from "react"
import ButtonNote from '../components/ButtonNote.jsx'
import {h1Class,buttons} from '../styles/index.module.scss'
import Tone from 'tone'
import CustomSound from '../components/CustomSound.jsx'

class HomePage extends Component {
    constructor(props){
        super(props)
        this.state = {
            notes: [
                {
                    note: 'Do',
                    value: 'C4'
                },
                {
                    note: 'Re',
                    value: 'D4'
                },
                {
                    note: 'Mi',
                    value: 'E4'
                },
                {
                    note: 'Fa',
                    value: 'F4'
                },
                {
                    note: 'Sol',
                    value: 'G4'
                },
                {
                    note: 'La',
                    value: 'A4'
                },
                {
                    note: 'Si',
                    value: 'B4'
                }
            ]
        }
    }

    playNote = (sound) => {
        const synth = new Tone.Synth().toMaster()
        synth.triggerAttackRelease(sound,'8n')
    }
    
    render(){
        const {notes} = this.state
        return (
            <div>
                <h1 className={h1Class}>Prueba de Sonido</h1>
                <div className={buttons}>
                {notes.map((val,ind)=>{
                    return (
                        <ButtonNote note={val.note} sound={val.value} playNote={this.playNote} key={ind}/>
                    )
                })}
                </div>
                <CustomSound />
        </div>
        )
    }
}

export default HomePage
