import React,{Component} from 'react'
import {panel} from '../styles/customSound.module.scss'
import {buttonClass} from '../styles/button.module.scss'
import Tone from 'tone'

class CustomSound extends Component {
    constructor(props){
        super(props)
        this.state = {
            note: '',
            duration: ''
        }
    }

    playNote = () => {
        const {note,duration} = this.state
        const synth = new Tone.Synth().toMaster()
        if(note && duration){
            synth.triggerAttackRelease(note,duration)
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render(){
        return (
            <div className={panel}>
                <h1>Nota Personalizada</h1>
                <div>
                    <div>
                        <h4>introduce una nota por ejemplo: "C4", "D3", "A5", "F6", etc...(sin comillas) </h4>
                        <input type="text" name="note" id="note" onChange={this.handleChange}/>
                    </div>
                    <div>
                    <h4>introduce una duración por ejemplo: "8n", "4n", "8t", etc... (sin comillas)</h4>
                        <input type="text" name="duration" id="duration" onChange={this.handleChange}/>
                    </div>
                </div>
                <input type="button" value="Play" className={buttonClass} onClick={this.playNote}/>
            </div>
        )
    }
}

export default CustomSound