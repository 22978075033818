import React,{Component} from 'react'
import {buttonClass} from '../styles/button.module.scss'
import Tone from 'tone'

class ButtonNote extends Component {
    constructor(props){
        super(props)
        this.state = {
            synth: null
        }
    }

    render(){
        const {note,playNote,sound} = this.props
        return (
            <input type="button" value={note} className={buttonClass} onClick={()=>{playNote(sound)}}/>
        )
    }
}

export default ButtonNote